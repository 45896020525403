export const isAdmin = () => {
    return true;
};

export const formatCurrencyAsString = (val) => {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
    });
    return formatter.format(val);
};

export const isBrowser = typeof window !== `undefined`;

export function hasPermissionsOf(user, role) {
    return user.roles.includes(role) || user.roles.includes("Admin");
}
