import auth0 from "auth0-js";
import { navigate } from "gatsby";
import { backend } from "../constants/backend";
import { CREATE_USER } from "../constants/mutations";
import {
    DOES_USER_EXIST,
    GET_USER_ROLES,
    GET_USER_BY_AUTH0_ID,
} from "../constants/queries";
import { isBrowser } from "../utils/helpers";

class Auth {
    auth_0 = isBrowser
        ? new auth0.WebAuth({
              domain: process.env.GATSBY_AUTH0_DOMAIN,
              clientID: process.env.GATSBY_AUTH0_CLIENTID,
              redirectUri: process.env.GATSBY_AUTH0_CALLBACK,
              audience: `https://${process.env.GATSBY_AUTH0_DOMAIN}/api/v2/`,
              responseType: "token id_token",
              scope: "openid profile email",
              leeway: 30,
          })
        : {};

    login = () => {
        if (isBrowser) {
            this.auth_0.authorize();
        }
    };

    logout = () => {
        if (isBrowser) {
            navigate("/");
            localStorage.removeItem("access_token");
            localStorage.removeItem("id_token");
            localStorage.removeItem("expires_at");
            localStorage.removeItem("user");
        }
    };

    handleAuthentication = () => {
        if (isBrowser) {
            // this must've been the trick
            this.auth_0.parseHash((err, authResult) => {
                if (
                    authResult &&
                    authResult.accessToken &&
                    authResult.idToken
                ) {
                    this.setSession(authResult, () =>
                        navigate("/blog-posts/latest")
                    );
                } else if (err) {
                    console.log(err);
                    alert("ERROR");
                    navigate("/");
                }
            });
        }
    };

    isAuthenticated = () => {
        if (isBrowser) {
            if (localStorage.getItem("expires_at") == null) {
                return false;
            }
            const expiresAt = JSON.parse(localStorage.getItem("expires_at"));
            return new Date().getTime() < expiresAt;
        }
    };

    setSession = (authResult, callback) => {
        if (isBrowser) {
            const expiresAt = JSON.stringify(
                authResult.expiresIn * 1000 + new Date().getTime()
            );
            localStorage.setItem("access_token", authResult.accessToken);
            localStorage.setItem("id_token", authResult.idToken);
            localStorage.setItem("expires_at", expiresAt);

            this.auth_0.client.userInfo(
                authResult.accessToken,
                async (err, user) => {
                    localStorage.setItem("user", JSON.stringify(user));

                    backend
                        .query({
                            query: DOES_USER_EXIST,
                            variables: { auth0_id: user.sub },
                        })
                        .then((result) => {
                            if (!result.data.does_user_exist) {
                                // Make a new user here
                                backend
                                    .mutate({
                                        mutation: CREATE_USER,
                                        variables: {
                                            auth0_id: user.sub,
                                            name: user.name,
                                            email: user.email,
                                            profile_image: user.picture,
                                        },
                                    })
                                    .then((result) => {
                                        callback();
                                    })
                                    .catch((error) => {
                                        throw error;
                                    });
                            } else {
                                callback();
                            }
                        })
                        .catch((error) => {
                            throw error;
                        });
                }
            );
        }
    };

    loadUserData = async (auth0_id) => {
        return backend
            .query({ query: GET_USER_BY_AUTH0_ID, variables: { auth0_id } })
            .then((result) => result.data.get_user_by_auth0_id);
    };

    getUser = async () => {
        if (isBrowser && localStorage.getItem("user")) {
            let user = JSON.parse(localStorage.getItem("user"));
            let userInfo = await this.loadUserData(user.sub);
            user = { ...user, ...userInfo };
            return user;
        }
    };

    getUserName = () => {
        if (isBrowser && this.getUser()) {
            return this.getUser().name;
        }
    };
}

export const auth = new Auth();
