import ApolloClient, { InMemoryCache } from "apollo-boost";
import fetch from "isomorphic-fetch";

export const backend = new ApolloClient({
    uri: process.env.GATSBY_API_URL,
    fetch,
    fetchOptions: {
        fetchPolicy: "no-cache",
    },
});

export const DEFAULT_FEATURE_IMAGE = `https://static.ghost.org/v3.0.0/images/welcome-to-ghost.png`;
