import { gql } from "apollo-boost";
import { auth } from "../utils/auth";
import { backend } from "./backend";

export const UPLOAD_IMAGE = gql`
    mutation upload_image($base64: String!) {
        upload_image(base64: $base64)
    }
`;

export const INSERT_POST = gql`
    mutation(
        $title: String!
        $tags: [String!]
        $author_email: String!
        $html: String!
        $feature_image: String
    ) {
        insert_post(
            title: $title
            tags: $tags
            author_email: $author_email
            html: $html
            feature_image: $feature_image
        )
    }
`;

export const UPDATE_POST = gql`
    mutation(
        $post_id: String!
        $title: String!
        $tags: [String!]
        $html: String!
        $feature_image: String
    ) {
        update_post(
            post_id: $post_id
            title: $title
            tags: $tags
            html: $html
            feature_image: $feature_image
        )
    }
`;

export const INSERT_COMMENT = gql`
    mutation insert_comment(
        $post_id: String!
        $auth0_id: String!
        $comment: String!
    ) {
        insert_comment(
            post_id: $post_id
            auth0_id: $auth0_id
            comment: $comment
        ) {
            comment
        }
    }
`;

export const CREATE_USER = gql`
    mutation create_user(
        $auth0_id: String!
        $name: String!
        $email: String!
        $profile_image: String!
    ) {
        create_user(
            auth0_id: $auth0_id
            name: $name
            email: $email
            profile_image: $profile_image
        ) {
            id
        }
    }
`;

export const INSERT_JOB = gql`
    mutation insert_job(
        $title: String!
        $company: String!
        $location: String!
        $description: String!
        $min_salary: Float!
        $max_salary: Float!
        $industries: String!
        $type: String!
        $job_functions: String!
        $contact_email: String!
        $employer_auth0_id: String!
    ) {
        insert_job(
            title: $title
            company: $company
            location: $location
            description: $description
            min_salary: $min_salary
            max_salary: $max_salary
            industries: $industries
            type: $type
            job_functions: $job_functions
            contact_email: $contact_email
            employer_auth0_id: $employer_auth0_id
        ) {
            title
        }
    }
`;

export const UPDATE_JOB = gql`
    mutation update_job(
        $id: ID!
        $title: String!
        $company: String!
        $location: String!
        $description: String!
        $min_salary: Float!
        $max_salary: Float!
        $industries: String!
        $type: String!
        $job_functions: String!
        $contact_email: String!
        $employer_auth0_id: String!
    ) {
        update_job(
            id: $id
            title: $title
            company: $company
            location: $location
            description: $description
            min_salary: $min_salary
            max_salary: $max_salary
            industries: $industries
            type: $type
            job_functions: $job_functions
            contact_email: $contact_email
            employer_auth0_id: $employer_auth0_id
        ) {
            title
        }
    }
`;

export const TOGGLE_LIKE_ON_POST = gql`
    mutation toggle_like_on_post($post_id: String!, $auth0_id: String!) {
        toggle_like_on_post(post_id: $post_id, auth0_id: $auth0_id)
    }
`;

export const UPDATE_USER_INFO = gql`
    mutation update_user_info(
        $auth0_id: String!
        $name: String
        $phone: String
        $gender: String
        $country: String
        $state: String
        $city: String
        $address: String
        $zip_code: String
    ) {
        update_user_info(
            auth0_id: $auth0_id
            name: $name
            phone: $phone
            gender: $gender
            country: $country
            state: $state
            city: $city
            address: $address
            zip_code: $zip_code
        ) {
            auth0_id
        }
    }
`;

export const DELETE_POST = gql`
    mutation delete_post($auth0_id: String!, $post_id: String!) {
        delete_post(auth0_id: $auth0_id, post_id: $post_id) {
            status
        }
    }
`;

export const DELETE_JOB_POST = gql`
    mutation delete_job_post($auth0_id: String!, $post_id: String!) {
        delete_job_post(auth0_id: $auth0_id, post_id: $post_id) {
            id
        }
    }
`;

// Helper functions

export const deletePost = async (post_id) => {
    let auth0_id = await auth.getUser().then((user) => user.sub);
    return backend
        .mutate({
            mutation: DELETE_POST,
            variables: {
                auth0_id,
                post_id,
            },
        })
        .catch((e) => {
            console.log(e);
            alert("An error has occurred. Please try again later. ");
        });
};

export const deleteJobPost = async (post_id) => {
    let auth0_id = await auth.getUser().then((user) => user.sub);
    return backend
        .mutate({
            mutation: DELETE_JOB_POST,
            variables: {
                auth0_id,
                post_id,
            },
        })
        .catch((e) => {
            console.log(e);
            alert("An error has occurred. Please try again later. ");
        });
};
