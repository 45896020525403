import { gql } from "apollo-boost";
export const GET_ALL_TAGS = gql`
    {
        get_all_tags {
            id
            name
        }
    }
`;

export const GET_COMMENTS_OF_POST = gql`
    query get_comments_of_post($post_id: String!) {
        get_comments_of_post(post_id: $post_id) {
            id
            comment
            date_posted
        }
    }
`;

export const DOES_USER_EXIST = gql`
    query does_user_exist($auth0_id: String!) {
        does_user_exist(auth0_id: $auth0_id)
    }
`;

export const GET_USER_ROLES = gql`
    query get_user_roles($auth0_id: String!) {
        get_user_roles(auth0_id: $auth0_id)
    }
`;

export const POST_WAS_LIKED = gql`
    query post_was_liked($post_id: String!, $auth0_id: String!) {
        post_was_liked(post_id: $post_id, auth0_id: $auth0_id)
    }
`;

export const GET_USER_BY_AUTH0_ID = gql`
    query get_user_by_auth0_id($auth0_id: String!) {
        get_user_by_auth0_id(auth0_id: $auth0_id) {
            id
            auth0_id
            name
            email
            profile_image
            info {
                phone
                gender
                country
                state
                city
                address
                zip_code
            }
            liked_posts {
                title
                id
                feature_image
                slug
                tags {
                    name
                }
                excerpt
                primary_author {
                    name
                    profile_image
                    slug
                }
            }
            published_posts {
                title
                id
                feature_image
                slug
                tags {
                    name
                }
                excerpt
                primary_author {
                    name
                    profile_image
                    slug
                }
            }
            published_jobs {
                id
                employer_auth0_id
                title
                company
                location
                description
                min_salary
                max_salary
                industries
                type
                job_functions
                contact_email
            }
            roles
        }
    }
`;

export const GET_POST_BY_ID = gql`
    query get_post_by_id($post_id: String!) {
        get_post_by_id(post_id: $post_id) {
            id
            title
            html
            feature_image
            tags {
                name
            }
        }
    }
`;

export const GET_JOB_BY_ID = gql`
    query get_job_by_id($id: ID!) {
        get_job_by_id(id: $id) {
            id
            employer_auth0_id
            title
            company
            location
            description
            min_salary
            max_salary
            industries
            type
            job_functions
            contact_email
        }
    }
`;
